import { AuthorType, SiteMetaData } from "@/types";

export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_DOMAIN ?? "https://restaurantassociation.com";

export const CONTENT_URL = process.env.NEXT_PUBLIC_CLOUDFRONT_URL;

export const defaultAuthor: AuthorType = {
  name: "Mitesh Gala",
  email: "marketing@restaurantassociation.com",
  website: "https://restaurantassociation.com/",
  company: "Restaurant Association",
  availableForWork: true,
  location: {
    city: "Costa Mesa",
    media: "/losangeles.jpg",
  },
};

export const jsonLdData = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  name: "Restaurant Association",
  url: "https://restaurantassociation.com/",
};

const defaultTitle = `Restaurant Association`;
const defaultDescription =
  "The best source for industry news, insider info, and operations tips for restaurant owners and foodservice leaders";

const siteMetadata: SiteMetaData = {
  title: {
    template: `%s | ${defaultTitle}`,
    default: defaultTitle,
  },
  description: defaultDescription,
  // siteRepo:
  // "https://amgit.altametrics.com/web-development-team/restaurantassociation-com",
  analyticsProvider: "google",
  defaultTheme: "light",
  activeAnnouncement: false,
  postsPerPage: 10,
  postsOnHomePage: 10,
  projectsOnHomePage: 4,
};

export default siteMetadata;
