import { TokenStore } from "@/types/auth";
import { useLocalStorage } from "@uidotdev/usehooks";
import axios from "axios";
import { toast } from "sonner";

export function useAuth() {
  const [_, saveUser] = useLocalStorage("user", null);
  const [tokens, saveTokens] = useLocalStorage<TokenStore>("tokens", {
    training_session: {
      token: "",
      exp: new Date(),
    },
    jobs_session: {
      token: "",
      exp: new Date(),
    },
  });

  async function logout() {
    let data,
      error = null;
    try {
      data = await axios.post("/api/unified/logout", {
        training_session: tokens.training_session,
        jobs_session: tokens.jobs_session,
      });

      saveUser(null);
      saveTokens({
        training_session: {
          token: "",
          exp: new Date(),
        },
        jobs_session: {
          token: "",
          exp: new Date(),
        },
      });
      return data;
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
    return { data, error };
  }
  return { logout };
}
