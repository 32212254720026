import { cn } from "@/utils/helpers/utils";

import { VariantProps, cva } from "class-variance-authority";
import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from "react";
import styles from "./GradientButton.module.css"; // Import CSS module

// Customized button variations
export const buttonVariants = cva(
  `focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent text-white font-semibold  rounded inline-flex items-center  justify-center
  `,
  {
    variants: {
      size: {
        xs: "px-2 py-1 text-xs gap-x-1.5",
        sm: "px-2 py-1 text-sm gap-x-1.5",
        md: "rounded px-2.5 py-1.5 text-sm gap-x-1.5",
        lg: "rounded px-3 py-2 text-sm gap-x-2",
        xl: "rounded px-3.5 py-3 text-sm gap-x-2",
      },
      variant: {
        primary:
          "text-white bg-brandOrange hover:brightness-110 focus-visible:outline-brandOrange",
        gradient: `${styles.gradientButton}`,
        gradientColor: `${styles.btnGrad}`,
        outline:
          "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-300",
        soft: "bg-indigo-50 text-indigo-600 hover:bg-indigo-100 focus-visible:outline-indigo-100",
        ghost:
          "bg-transparent text-gray-900 hover:bg-gray-50 focus-visible:outline-gray-300",
        link: "focus-visible:outline-accent font-semibold leading-6 text-accent hover:brightness-110        shadow-none",
      },
      pill: {
        true: "rounded-full",
        false: "",
      },
    },
    defaultVariants: {
      size: "xl",
      variant: "primary",
      pill: false,
    },
  },
);

// ButtonProps interface
export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { size, variant, pill, className, children, ...props },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        className={cn(buttonVariants({ className, variant, size, pill }))}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
