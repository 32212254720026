import(/* webpackMode: "eager" */ "/code/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/components/ui/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/components/ui/Image/OptimizedImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/components/ui/Newsletter/newsletterPopupRefactor.tsx");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.8_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.8_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.8_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.8_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/.pnpm/next@14.2.8_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/code/node_modules/.pnpm/sonner@1.4.41_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/code/services/redux/provider.tsx");
