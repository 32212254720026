// store/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { IVideo } from "@/types/foodInsider";

import e, { $infer } from "@/dbschema/edgeql-js";
import { Videos } from "@/dbschema/interfaces";

// interface VideoState {
//   nowPlaying: IVideo | Videos[] | null;
//   upNext: IVideo[] | Videos[][];
//   recommended: IVideo[] | Videos[][];
//   videos: IVideo[] | Videos[][];
//   episodes: IVideo[] | Videos[][];
//   allVideos: IVideo[] | Videos[][];
// }
interface VideoState {
  nowPlaying: Videos | null;
  upNext: Videos[];
  recommended: Videos[];
  videos: Videos[];
  episodes: Videos[];
  allVideos: Videos[];
}

const initialState: VideoState = {
  nowPlaying: null,
  upNext: [],
  recommended: [],
  videos: [],
  episodes: [],
  allVideos: [],

  // ... any other auth related state
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setNowPlaying: (state, { payload }: { payload: Videos }) => {
      // state.user = action.payload;
      state.nowPlaying = payload;
    },
    setUpNext: (state, { payload }: { payload: Videos[] }) => {
      state.upNext = payload;
    },
    setRecommended: (state, { payload }: { payload: Videos[] }) => {
      state.recommended = payload;
    },
    setVideos: (state, { payload }: { payload: Videos[] }) => {
      state.videos = payload;
    },
    setEpisodes: (state, { payload }: { payload: Videos[] }) => {
      state.episodes = payload;
    },
    setAllVideos: (state, { payload }: { payload: Videos[] }) => {
      state.allVideos = payload;
      const allEpisodeContent = payload.filter(
        (vid) => vid.type === "episodes",
      );
      // const allEpisodeContent = payload.filter(
      //   (vid) => vid.data.category === "episodes",
      // );
      // state.episodes = payload.filter(
      //   (vid) => vid.data.category === "episodes",
      // );
      setEpisodes(allEpisodeContent);
      const allVideoContent = payload.filter((vid) => vid.type === "videos");
      setVideos(allVideoContent);
      // state.videos = payload.filter((vid) => vid.data.category === "videos");
      const copy = [...payload];
      // copy.sort(
      //   (a, b) =>
      //     new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime(),
      // );
      state.upNext = payload;
    },
    // ... any other reducers you might need
  },
});

export const {
  setNowPlaying,
  setRecommended,
  setUpNext,
  setAllVideos,
  setVideos,
  setEpisodes,
} = videoSlice.actions;

export default videoSlice.reducer;
