"use client";
import Image, { ImageProps } from "next/image";
import { useState } from "react";

export interface OptimizedImageProps extends ImageProps {
  src: string;
  fallbackSrc?: string;
  placeholderSrc?: string;
  loadingComponent?: React.ReactNode;
  priority?: boolean;
  cloudfront?: boolean;
  preload?: boolean; // Preload prop
}

const Img: React.FC<OptimizedImageProps> = ({
  src,
  alt,
  fallbackSrc = "/placeholders/default.webp",
  placeholderSrc,
  priority = false,
  cloudfront = true,
  preload = false, // Preload images if needed
  ...props
}) => {
  // const [imageSrc, setImageSrc] = useState(
  //   cloudfront ? `https://d3n2401vhvcfv5.cloudfront.net${src}` : src,
  // );

  const [imageSrc, setImageSrc] = useState(
    cloudfront
      ? `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL ?? process.env.CLOUDFRONT_URL}${src}`
      : src,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <Image
      src={hasError ? fallbackSrc : imageSrc}
      alt={alt}
      decoding="async"
      loading={priority ? "eager" : "lazy"}
      onError={() => setHasError(true)}
      {...props}
      style={{
        opacity: isLoading ? 0 : 1,
        transition: "opacity 0.5s ease-in-out",
        ...props.style,
      }}
    />
  );
};

export default Img;

// export default OptimizedImage;
