"use client";
import { cn } from "@/utils/helpers/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
import { IoClose } from "react-icons/io5";
import Button, { buttonVariants } from "../Button";
import OptimizedImage from "../Image/OptimizedImage";
import { AUTH_LINKS } from "@/lib/navigation-links";

const content = {
  title: "Become a member of the Restaurant Association!",
  details: {
    title: (
      <p className="space-x-1 text-lg font-semibold">
        <span className="italic text-blue">Free </span>
        <span> Membership Includes:</span>
      </p>
    ),
    list: [
      "Exclusive access to webinars",
      "Latest restaurant news",
      "Podcasts and inside peeks",
    ],
  },
};

function NewsletterPopup({ token }: any) {
  const [show, setShow] = React.useState(false);
  const pathname = usePathname();
  const signupPath = [
    AUTH_LINKS.signup.href + "/",
    AUTH_LINKS.login.href + "/",
    AUTH_LINKS.success.href + "/",
  ];

  console.log(pathname, { signupPath });

  React.useEffect(() => {
    // Set a timer to show the popup after 5 seconds
    const timer = setTimeout(() => {
      setShow(true);
    }, 5000);
    if (signupPath.includes(pathname) || token) {
      clearTimeout(timer);
      setShow(false);
    }

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [pathname]);

  return (
    <div className=" fixed bottom-0  right-0 z-50  w-full min-w-[350px]  px-2 lg:right-5 lg:max-w-[550px] lg:px-0  ">
      <div
        className={` w-full min-w-[350px] rounded-md border   ${show ? "translate-y-0 " : "hidden translate-y-full"}  grid grid-cols-11 gap-1 rounded-b-none border-2 border-black bg-white transition-all`}
        style={{
          boxShadow: "0 0 10px 0 rgba(0,0,0,.4)",
        }}
      >
        {/* <div className=""> */}
        <Button
          className="absolute right-2 top-2 z-10 cursor-pointer bg-slate-50/50 hover:bg-slate-50"
          variant="soft"
          size="sm"
          onClick={() => setShow(false)}
        >
          <IoClose className="text-black" />
        </Button>
        {/* </div> */}
        {/* <NewsletterBorderedMini className="" /> */}

        <div className="order-2 col-span-full  flex justify-start  md:order-1 md:col-span-6">
          <PopupCTA />
        </div>
        <div className="absolute -z-50 col-span-full aspect-video h-full w-full  overflow-clip  md:relative md:z-0 md:order-2 md:col-span-5">
          <OptimizedImage
            src={"/_images/ui/waiting-order.webp"}
            // fill
            alt="Restaurant Association Membership Signup"
            style={{
              objectFit: "cover",
              objectPosition: "55% 50%",
            }}
            sizes={"(min-width: 1024px) 50vw ,100vw"}
            // style={{ objectFit: "contain" }}
            className=""
            fill
            priority
            preload
          />
        </div>
      </div>
    </div>
  );
}

export default NewsletterPopup;

// function PopupCTA() {
// // { text, className, onClick }: { text: string; className?: string; onClick?: () => void },
//   return <div>dasf</div>;
// }
export function PopupCTA({
  closePopup,
  className,
  customButton,
}: {
  closePopup?: () => void;
  className?: string;
  customButton?: boolean;
}) {
  return (
    <div className=" grid w-full grid-cols-3 gap-8">
      <div
        //text-[#323239]
        className={cn(
          "col-span-3 flex flex-col items-start gap-4  rounded-md  bg-black/60 px-4  py-4 text-left text-white md:bg-white md:text-[#323239] lg:py-8",
          className,
        )}
      >
        <h3 className="text-2xl font-semibold leading-[1.2] before:block before:w-[80px] before:border-t-4 before:border-blue before:pb-3 before:content-[''] lg:text-[1.625rem]">
          {content.title}
        </h3>

        <div className="">
          {/* <p className="text-base ">{content.details.title}</p> */}
          {content.details.title}
          <ul className="pl-6 text-left">
            {content.details.list.map((item, index) => (
              <li key={index} className="list-disc text-base">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <Link
          href={AUTH_LINKS.signup.href}
          className={cn(
            buttonVariants({
              className: cn(
                "shadow-none",
                "w-full  rounded border-0    text-white",
                "bg-blue shadow-none",
              ),
              variant: !customButton ? "gradientColor" : "primary",
            }),
          )}
          style={{ boxShadow: "none" }}
          onClick={closePopup ? closePopup : undefined}
        >
          Sign up
        </Link>
      </div>
    </div>
  );
}
