// store/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { Users } from "@/dbschema/interfaces";

interface authState {
  user: Users | null;
  isAuthenticated: boolean;
  authToken: string;
}
const initialState: authState = {
  user: null,
  isAuthenticated: false,
  authToken: "",
  // ... any other auth related state
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      // state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.authToken = "";
    },
    setAuthToken: (state, { payload }: { payload: string }) => {
      state.authToken = payload;
      state.isAuthenticated = !!payload;
    },
    setUser: (state, { payload }: { payload: Users }) => {
      state.user = payload;
    },
    setIsAuthenticated: (state, { payload }: { payload: boolean }) => {
      state.isAuthenticated = payload;
    },
    // ... any other reducers you might need
  },
});

export const { login, logout, setAuthToken, setUser, setIsAuthenticated } =
  authSlice.actions;

export default authSlice.reducer;
